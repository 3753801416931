import React from 'react';
import { Typography, Slider } from '@mui/material';

const FontSizeSlider = ({ fontSize, setFontSize }) => {
    return (
        <>
            <Typography gutterBottom sx={{ color: '#0057b7' }}>
                Font Size
            </Typography>
            <Slider
                value={fontSize}
                onChange={(e, newValue) => setFontSize(newValue)}
                aria-labelledby="font-size-slider"
                min={10}
                max={100}
                sx={{ color: '#0057b7' }}
            />
        </>
    );
};

export default FontSizeSlider;
